import React from 'react';
import { Row } from '@tanstack/react-table';
import VisibilityIconButton from 'components/bricks/components/shared/components/visibility-icon';
import Badge from 'components/ui-components-v2/Badge';
import DotIndicator, { DotIndicatorSeverity, TooltipValidationResults } from 'components/bricks/components/shared/components/dot-indicator';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Translation from 'components/data/Translation';
import TreeViewItem from '../types/tree-view-item.type';

import '../styles/actions.scss';

interface Props {
    item: Row<TreeViewItem>;
    previewItemId?: string;
    className?: string;
    showPreviewIcon?: boolean;
    severity?: DotIndicatorSeverity;
    tooltip?: TooltipValidationResults;
    disableTooltipHover?: boolean;
    onPreviewChange?: (event: React.MouseEvent, previewItemId: string) => void;
}

/**
 * This component is responsible for rendering the tree view list item actions.
 */
const TreeViewActions: React.FC<Props> = ({ item, previewItemId, className, showPreviewIcon, severity, tooltip, disableTooltipHover, onPreviewChange }) => {
    const isPreviewActive = previewItemId === item.id;
    const numberOfChildren = item.subRows.length;
    const badgeCounterTooltip = `${Translation.get('labels.has', 'common')} ${numberOfChildren} ${Translation.get('labels.children', 'common')}`;

    return (
        <div className={`tree-view-actions ${className}`}>
            <Tooltip title={Translation.get('showPreview', 'bricks')}>
                <div className="tree-view-actions__visibility-icon">
                    {onPreviewChange && (showPreviewIcon || isPreviewActive) && (
                        <VisibilityIconButton isActive={isPreviewActive} onClick={(event) => onPreviewChange(event, item.id)} />
                    )}
                </div>
            </Tooltip>

            <Tooltip title={badgeCounterTooltip}>
                <div className={'tree-view-actions__badge-counter'}>
                    {numberOfChildren ? <Badge color={'default'} badgeContent={numberOfChildren} /> : null}
                </div>
            </Tooltip>
            <DotIndicator severity={severity} tooltip={tooltip} disableTooltipHover={disableTooltipHover} />
        </div>
    );
};

export default TreeViewActions;
