import React from 'react';
import classNames from 'classnames';

import '../styles/disabled-overlay.scss';

interface Props {
    isDisabled?: boolean;
    children?: React.ReactNode;
}

/**
 * This component is responsible for adding a light filter gray color on the children when the isDisabled prop is true.
 */
const DisabledOverlay: React.FC<Props> = ({ isDisabled, children }) => {
    return <div className={classNames({ 'disabled-overlay': isDisabled })}>{children}</div>;
};

export default DisabledOverlay;
