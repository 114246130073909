import React from 'react';
import classNames from 'classnames';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import Icon from 'components/ui-components-v2/Icon';
import { BrickSetup, BrickSubType } from 'components/bricks/types/brick.type';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import Loader from 'components/ui-components/Loader';
import GenericIcon, { GenericSvgIcon } from 'components/ui-components/GenericIcon';

import './main.scss';

interface Props {
    subType: BrickSubType | undefined;
    brickId?: string;
    width?: number;
    height?: number;
    noBackground?: boolean; // Don't show the lightgrey background
    showLogo?: boolean; // Show the original company logo (so meta logo instead of adset folder icon)
    warnings?: string[];
    errors?: string[];
    isLoading?: boolean; // If true, then a loading spinner will be shown
}

/**
 * This component gets the icon from a specific block and puts an optional lightgrey background around it. The icon can either
 * be an svg or an <Icon> element
 * @param subType The subType
 * @returns An icon with a lightgrey square around it
 */
const TypeIcon: React.FC<Props> = ({ subType, brickId, width, height, noBackground = false, showLogo = false, warnings, errors, isLoading = false }) => {
    const brick = BrickHelpers.getBrickById(brickId);
    const creativeThumbnail: string | undefined = brick?.data?.creatives?.length > 0 ? brick?.data?.creatives[0]?.thumbnail : undefined;

    const getIcon = () => {
        if (!subType) return;
        const setup = BrickHelpers.getBrickData(subType, 'setup') as BrickSetup;

        // If it's an asset, show the image of the asset
        if (subType === 'single_asset' && brickId) {
            if (brick?.data?.creatives && brick?.data?.creatives.length > 0) {
                if (brick?.data?.creatives[0]?.data?.fileType === 'zip' || brick?.data?.presets?.[0]?.assetType === 'zip') {
                    if (brick?.data?.creatives[0]?.data?.thumbnail || brick?.data?.creatives[0]?.data?.backupImage) {
                        return (
                            <img
                                src={brick?.data?.creatives[0]?.data?.thumbnail || brick?.data?.creatives[0]?.data?.backupImage}
                                className="bricks-shared__type-icon__img"
                            />
                        );
                    }

                    return <Icon>folder_zip</Icon>;
                }

                if (brick?.data?.creatives[0]?.data?.fileType === 'audio') {
                    return <Icon>music_note</Icon>;
                }

                if (brick?.data?.creatives[0]?.data?.fileType === 'video') {
                    if (brick?.data?.creatives[0]?.data?.thumbnail || brick?.data?.creatives[0]?.data?.backupImage) {
                        return (
                            <img
                                src={brick?.data?.creatives[0]?.data?.thumbnail || brick?.data?.creatives[0]?.data?.backupImage}
                                className="bricks-shared__type-icon__img"
                            />
                        );
                    }

                    return <Icon>movie</Icon>;
                }

                return (
                    <img
                        src={brick?.data?.creatives[0]?.data?.thumbnail || brick?.data?.creatives[0]?.data?.backupImage}
                        className="bricks-shared__type-icon__img"
                    />
                );
            }
        }

        if (creativeThumbnail) {
            return <img src={creativeThumbnail} className="bricks-shared__type-icon__img" />;
        }

        const platform = setup.platform;

        const icon: string | JSX.Element | undefined = BrickHelpers.getBrickData(subType, 'icon');

        // If we want to show the logo we need to get it from the generic icons
        if (showLogo || !icon) {
            if (platform) return <GenericIcon svgClassName="bricks-shared__type-icon__svg" icon={platform as GenericSvgIcon} />;
            return '';
        }

        if (typeof icon === 'string') {
            return <img src={icon} />;
        } else {
            return icon;
        }
    };

    return (
        <div className={classNames('bricks-shared__type-icon', { 'no-background': noBackground })} style={{ width: width || '100%', height: height || '100%' }}>
            <Loader
                classes={{
                    root: 'bricks-shared__type-icon__loader-root',
                    loaderBox: 'bricks-shared__type-icon__loaderBox',
                    loader: 'bricks-shared__type-icon__loader'
                }}
                isLoading={isLoading}>
                {getIcon()}
                {((warnings && !!warnings.length) || (errors && !!errors.length)) && (
                    <div className="bricks-shared__type-icon__overlay">
                        {warnings && (!errors || errors.length === 0) && <WarningIcon className="bricks-shared__type-icon__overlay__warning-icon" />}
                        {errors && errors.length > 0 && <ErrorIcon className="bricks-shared__type-icon__overlay__error-icon" />}
                    </div>
                )}
            </Loader>
        </div>
    );
};

export default TypeIcon;
