import React from 'react';
import IconButton from '@mui/material/IconButton';
import Icon from 'components/ui-components-v2/Icon';

interface Props {
    isActive: boolean;
    onClick: (event: React.MouseEvent) => void;
}

/**
 * This component is responsible for rendering the visibility icon button.
 */
const VisibilityIconButton: React.FC<Props> = ({ isActive, onClick }) => {
    const color = isActive ? 'primary' : undefined;

    return (
        <IconButton color={color} onClick={onClick}>
            <Icon>visibility</Icon>
        </IconButton>
    );
};

export default VisibilityIconButton;
